/* Shop.css */

.loaderContainer {
  height: calc(90vh - 100px);
  position: relative;
}
.loader {
  border: 12px solid #f3f3f3;
  border-top: 11px solid black;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  animation: rotate 2s linear infinite;
  /* background-color: black; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.localityInfo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(235, 239, 245);
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.listOfShops {
  padding-top: 1px;
  background-color: rgb(235, 239, 245);
}
.shopContent {
  position: relative;
  min-height: calc(100vh - 70px);
}
.listHeading {
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 50px;
  text-align: center;
  background-color: white;
  padding-left: 20px;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.shopCarousel {
  width: 99%;
  background-color: white;
  justify-content: space-around;
  display: flex;
  padding: 30px 0;
  min-height: 500px;
  flex-wrap: wrap;
  margin: 5px auto;
}

.shopCarousel .card {
  margin: 20px auto;
}
.card-title {
  text-transform: uppercase;
  color: black;
}
.card-body {
  color: #5e6f64;
  padding: 8px;
  font-size: 13px;
}
.card-footer {
  background-color: white;
  border-top: none;
  color: #4d3e3e;
  margin: 12px 0;
  padding: 8px;
  padding-left: 0;
}

.card-img-top {
  height: 130px;
  /* width: 200px; */
}
.visitLink {
  border-radius: 5px;
  display: block;
  color: white !important;
  font-weight: 500;
  background-color: #e74c3c;
  padding: 8px 4px;
  padding-left: 5px;
  font-size: 14px;
  width: 100px;
  transition: all 0.3s ease;
}
.visitLink:hover {
  color: inherit;
}
.noShopPromptButton {
  background-color: #ed611a;
  color: white;
  font-size: 14px;
  padding: 8px 12px;
  margin: auto;
}
.noPromptAndNoLocation h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

@media (max-width: 650px) {
  .noPromptAndNoLocation h4 {
    font-size: 1.1rem;
  }
  .localityInfo h4 {
    font-size: 15px;
    margin-bottom: 0;
  }
  .localityInfo p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

/* app css */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
body{
  background-color: #fff;
}
* input::-webkit-outer-spin-button,
* input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}

a{
  color: white;
}
a:hover{
  color: #f0f0f0;
  text-decoration: none;
}
*::-webkit-scrollbar {
  width: 2px;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #408fcc80;
}
 
*::-webkit-scrollbar-thumb {
  background-color: #b6eb7a;
}